/* eslint-disable react-hooks/rules-of-hooks */
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { ArticlePageProps } from 'pages/[...slug]';
import { Link } from '@components/Link';
import { PortalData, useTracker } from '@utils/snowplowTracking';
import { findFirstDataNode } from '@utils/helpers/misc';
import { GA4TrackLinkClick } from '@utils/tracking';
import { useRuntimeSettings } from '@utils/config';

import { ParserComponentBasicProps, textContent } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';
import { isDomNode } from '../util';
import { isMobileAppUrl, MobileAppLink } from './MobileAppLink';

export type LinkRendererProps = {
  linksMetadata?: ArticlePageProps['textLinks'];
  context: SelfDescribingJson<PortalData>;
};

export default function CdsLinkRenderer({
  domNode,
  renderDomNode,
  linksMetadata,
  context,
}: ParserComponentBasicProps & LinkRendererProps) {
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();

  if (!isDomNode(domNode)) {
    return <></>;
  }

  const linkToken =
    (domNode.attribs && domNode.attribs['data-pcs-link-token']) || '';

  const url =
    linksMetadata &&
    linksMetadata?.find((link) => link.replacementToken === linkToken);

  if (isMobileAppUrl(url?.externalUrl)) {
    return (
      <MobileAppLink label={textContent(domNode)} url={url?.externalUrl} />
    );
  }

  // if direct child is img let it be handled by the ImgRenderer without wrapping
  if (domNode.children && domNode.children[0]?.name === 'img') {
    return <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />;
  }

  if (url) {
    const { trackLinkClick } = useTracker(CdsLinkRenderer.name);
    const linkLabel = findFirstDataNode(domNode) || '';
    const articleTitle = context.data?.question;
    return (
      <Link
        href={url.externalUrl}
        underlined
        onClick={() => {
          trackLinkClick?.(url.externalUrl, linkLabel);
          enableGA4Tracking &&
            GA4TrackLinkClick(
              url.externalUrl,
              linkLabel,
              undefined,
              'Article Page',
              undefined,
              articleTitle,
              1,
              2,
            );
        }}
      >
        <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />
      </Link>
    );
  }

  return <></>;
}
