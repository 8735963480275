import { DomElement } from 'html-react-parser';

export type ParserComponentBasicProps = {
  domNode: DomElement | DomElement[];
  renderDomNode: (el: DomElement, headlineRank?: 2 | 3) => any;
  headlineRank?: 2 | 3;
};

export function isDomNode(
  domNode: DomElement | DomElement[],
): domNode is DomElement {
  return !Array.isArray(domNode);
}

export function textContent(domNode: DomElement): string {
  if (domNode.type === 'text') {
    return domNode.data ?? '';
  }
  return (domNode.children ?? []).reduce(
    (text, node) => `${text}${textContent(node)}`,
    '',
  );
}
